import React, { useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import pic1 from "../assets/img/images/executive-vanterra.png";
import pic2 from "../assets/img/images/humer-limosine.png";
import pic3 from "../assets/img/images/limo-bus.png";
import pic4 from "../assets/img/images/limosine.png";
import pic5 from "../assets/img/images/luxury-sedans.png";
import pic6 from "../assets/img/images/minibus.png";
import pic7 from "../assets/img/images/motorcoach.png";
import pic8 from "../assets/img/images/sprinterlimo.png";
import pic9 from "../assets/img/images/suvs.png";
import pic10 from "../assets/img/images/van-terra.png";
import pic11 from "../assets/img/images/wc-vehicles.png";
import "react-icons/fa";
import "./Section.css";

const Section = () => {
    const containerRef = useRef(null);

    const [activeSlide, setActiveSlide] = useState(0);

    const handleSlideChange = (slideIndex) => {
        setActiveSlide(slideIndex);
    };

    const shouldAnimate = activeSlide === 0; // Check if it's the fourth slide
    const shouldAnimate2 = activeSlide === 1;
    const shouldAnimate3 = activeSlide === 2;


    return (
        <section className='Section' id='fleet'>

            <div className='productsheading'>
                <br />
                <h1 style={{ fontSize: "40px",color:"orangered" }} >Our Fleet</h1>
                <p>Our diverse fleet of more than 200 late-model vehicles<br /> from luxury sedans to
                    motorcoaches can accommodate all leisure and corporate needs, from small groups to large events.<br />
                    We have a fleet of vehicles that includes limousines, buses, SUVs, and sedans. Our vehicles are always
                     maintained and inspected.</p>

            </div>

            <Carousel className='react-carousel1' ref={containerRef} autoPlay={true}
                onChange={handleSlideChange}
                interval={3500} transitionTime={1000} showThumbs={false} showStatus={false} infiniteLoop={true}>


                <div className='slide1'>
                    <div className={`sectioncontainer ${shouldAnimate ? 'text-animation' : ''}`}>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic1} alt='tasveer' /></div>
                            <h3>Executive Van Terra</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic2} alt='tasveer' /></div>
                            <h3>Hummer Limousine</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic3} alt='tasveer' /></div>
                            <h3>Limo Bus</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic4} alt='tasveer' /></div>
                            <h3>Limousine</h3>
                        </div>
                    </div>
                </div>

                <div className='slide1'>
                    <div className={`sectioncontainer ${shouldAnimate2 ? 'text-animation' : ''}`}>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic5} alt='tasveer' /></div>
                            <h3>Luxury Sedans</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic6} alt='tasveer' /></div>
                            <h3>Minibus</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic7} alt='tasveer' /></div>
                            <h3>Motorcoach</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic8} alt='tasveer' /></div>
                            <h3>Sprinter Limo</h3>
                        </div>
                    </div>
                </div>

                <div className='slide1'>

                    <div className={`sectioncontainer ${shouldAnimate3 ? 'text-animation' : ''}`}>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic9} alt='tasveer' /></div>
                            <h3>SUV</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic10} alt='tasveer' /></div>
                            <h3>Van Terra</h3>
                        </div>
                        <div className='sectionitems'>
                            <div className='pic'><img src={pic11} alt='tasveer' /></div>
                            <h3>Wheelchair-accessible</h3>
                        </div>
                    </div>

                </div>


            </Carousel>
        </section>
    );
}

export default Section;