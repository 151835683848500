import React from "react";
import "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import Form from "./Form";

export const Intro = () => {


  return (
    <section className="intro" id="home">
      <div className="introContainer">
        <div className="text">
          <p className="gradient-text">Featured</p>
          <h1>NORTH STAR LIMO</h1>
          <p>When talking about the best limo service provider, you will always hear about our Company.
            We have made a name for ourselves through the high-quality service and unmatched customer service that we have.
            For any transportation service needs you have, you can trust us to deliver a 100% satisfactory service.</p>
          <p><FaCheck style={{ color: "orange" }} /> On-Time Guarantee<br />
            <FaCheck style={{ color: "orange" }} /> All Inclusive Pricing<br />
            <FaCheck style={{ color: "orange" }} /> Professional Drivers</p>

          <button><a style={{ textDecoration: "none", color: "white" }} href="tel:14752634964"  >CALL NOW</a></button>
        </div>


        <div className="calender">
          <Form />

        </div>

      </div>

    </section>
  );
};
