import React from "react";
import { FaCheck } from "react-icons/fa";
import Section from "./Section";

export const ContactUs = () => {

  return (
    <section className="Contact">


      <Section />

    </section>
  );
};
