
import { FaQuoteLeft, FaStar } from "react-icons/fa";
import "react-icons/md";
import img1 from "../assets/img/reviews1.png";
import { BsBlockquoteLeft, BsChatLeftQuote } from "react-icons/bs";
import { MdFormatQuote } from "react-icons/md";


export const Testimon = () => {
  return (
    <section className="Testimon" id="reviews">

      <div className="clients">
        
        <p style={{color:"orange", fontWeight:"bold"}}>Testimonials</p>
        <h1>Our Satisfied Clientele...</h1>
        <p>"Drawing in delighted clients with our exceptional testimonials!"</p>
        

      </div>
      <br/><br/>

      <div className="TestimonContainer">



      <div className="elements">

        <FaQuoteLeft color="red" fontSize="2em" />
        
        <br />
        <br />
        We found the best vehicle that can transport everyone to our destination. It was a comfortable and stress-free
         travel experience everyone had after a long while. A job well done for our wedding transportation needs.
        <br />
        <h3>Jacob B.</h3>
      </div>

      <div className="elements">

        <FaQuoteLeft fontSize="2em" />
        
        <br />
        <br />
        For the best ground transportation, we’ll always go with North Star Limo.
         Their service is one of the best we have had so far. Not only are their chauffeurs professional in driving,
         but they are also well-dressed. It matched the luxurious feel of the vehicle; it’s the best.
        <br />
        <h3>Taylor T.</h3>
      </div>

      <div className="elements">

        <FaQuoteLeft color="red" fontSize="2em" />
        
        <br />
        <br />
        Choosing North Star Limo for our wine tour is the best decision we ever made. Hands down,
         the best experience for my friends I had. The ride was smooth,
         and we arrived on time with a few minutes to spare. Highly recommended ground transportation for any occasion.
        <br />
        <h3>Hannah S.</h3>
      </div>

      <div className="elements">

        <FaQuoteLeft fontSize="2em" />
        
        <br />
        <br />
        The vehicle is spacious, clean, and has a luxurious feel to it. There is enough space for our bags,
         and we can still stretch our legs. Another impressive thing that we want to point out is the time we
          arrived at our destination. The service is prompt,
         and the driver is courteous. We will definitely keep in touch for when we need ground transportation next time.
        <br />
        <h3>Sophie T.</h3>
      </div>

      
      </div>

      <div className="twoStrip">
        <div className="special">
          <p>Verify service availability in your vicinity...</p>
          <h2>Reach Out to Our Expert Today!</h2>
        </div>
        <div className="num">
          <p>Dial Now!</p>
          <h1 style={{color:"black"}}><a style={{textDecoration:"none", color:"black"}} href="tel:14752634964">+1 (475) 263-4964</a></h1>
        </div>
      </div>

    </section>
  );
};
