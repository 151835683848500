import React from 'react';
import { FaMailchimp } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export const Footer = () => {

  return (
    <section className="footer-container">



      <div className='footerBox'>
        <div className='about'>
          <h2>About Us</h2>
          <p>North Star Limo stands as the pinnacle in luxury transportation, renowned for our
            unwavering commitment to exceptional service. Our name echoes reliability and sophistication,
            offering top-tier experiences that surpass expectations. From corporate
            travel to special events, we redefine luxury, ensuring every journey is an epitome of elegance and satisfaction.</p>

        </div>

        <div className='quicklinks'>
          <h2>Quicklinks</h2>
          <a style={{ textDecoration: "none", color: "white" }} href="#home">
            <p>. Home</p></a>
          <a style={{ textDecoration: "none", color: "white" }} href="#services">
            <p>. Explore Our Range of Services</p></a>
            <a style={{ textDecoration: "none", color: "white" }} href="#faqs">
            <p>. View our FAQs</p></a>
            
          <a style={{ textDecoration: "none", color: "white" }} href="#fleet">
            <p>. Discover Our Vehicles</p></a>
          <a style={{ textDecoration: "none", color: "white" }} href="#reviews">
            <p>. Read Customer Feedback</p></a>
        </div>



        <div className='numa'>

          <h2>Contact Info</h2>
          <p>Dial Now!</p> 

           <h2>
           <a style={{ textDecoration: "none", color: "white" }} href="tel:14752634964" >(475) 263-4964</a></h2>

          <p style={{ color: "goldenrod" }}><FaMailchimp /> <a style={{ textDecoration: "none", color: "goldenrod", fontSize: "16px" }} className="email" href="mailto:contact@mynorthstarlimo.com ">
            Email Now
          </a> </p>

          <p><b>Address:</b> 245 Colonial Ave, Unit 1A, Waterbury, CT 06704</p>

        </div>
      </div>

      <div>

        <hr style={{ width: "70%" }} />

        <p>Copyright © 2023 North Star Limo - All Rights Reserved</p>

      </div>

    </section>);


};
