import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { FaCalendar, FaClock, FaLocationArrow, FaSearchLocation, FaUser, FaPhone, FaPen, FaEnvelope } from 'react-icons/fa';
import { GiShoppingBag } from "react-icons/gi";
import "./Form.css";

const Form = () => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [step, setStep] = useState(1); // state to track the current step
    const navigate = useNavigate();
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        if (isFormValid) {
            emailjs
                .sendForm(
                    "service_j058m67",
                    "template_g2ln0g2",
                    form.current,
                    "PZtf-SW7lqTRrSJ1-"
                )
                .then(
                    (result) => {
                        setAlertMessage('Thank you for filling in the information! We will get back to you with a price quotation shortly.');
                        setAlertType('success');
                        setShowAlert(true);
                        form.current.reset();
                        navigate("/success");
                    },
                    (error) => {
                        setAlertMessage('Failed to send message. Please try again later.');
                        setAlertType('error');
                        setShowAlert(true);
                    }
                );
        } else {
            setAlertMessage('Please fill in all required fields before submitting.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setIsFormValid(value.trim() !== '');
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    return (
        <section>
            <div className='form1'>
                {showAlert && (
                    <div className={`alert alert-${alertType}`}>
                        <span className="closebtn" onClick={() => setShowAlert(false)}>&times;</span>
                        {alertMessage}
                    </div>
                )}
                <form ref={form} onSubmit={sendEmail}>
                    {step === 1 && (
                        <>
                            <h3>From</h3>
                            <div className='input1'>
                                <FaLocationArrow style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="text" placeholder='Address, airport, hotel, ...' name='fromAddress' onChange={handleInputChange} required />
                            </div>

                            <h3>To</h3>
                            <div className='input1'>
                                <FaSearchLocation style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="text" placeholder='Address, airport, hotel, ...' name='toAddress' onChange={handleInputChange} required />
                            </div>

                            <h3>Date</h3>
                            <div className='input1'>
                                <FaCalendar style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="date" name="date" onChange={handleInputChange} required />
                            </div>

                            <h3>No. of Passengers</h3>
                            <div className='input1'>
                                <FaUser style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="number" name="passengers" placeholder='Number of Passengers' onChange={handleInputChange} required />
                            </div>

                            <h3>Luggage</h3>
                            <div className='input1'>
                                <GiShoppingBag style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="number" name="luggage" placeholder='Number of Luggage' onChange={handleInputChange} required />
                            </div>



                            <br />
                            <button className='buttonx' type='button' onClick={handleNextStep}>Next</button>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <h3>Name</h3>
                            <div className='input1'>
                                <FaPen style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="text" name="name" placeholder='Full Name' onChange={handleInputChange} required />
                            </div>

                            <h3>Contact No.</h3>
                            <div className='input1'>
                                <FaPhone style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="tel" name="phone" placeholder='Phone Number' onChange={handleInputChange} required />
                            </div>
                            <h3>Email</h3>
                            <div className='input1'>
                                <FaEnvelope style={{ color: "orangered", fontSize: "18px" }} />
                                <input type="email" name="email" placeholder='Email Address' onChange={handleInputChange} />
                            </div>
                            <br />
                            <button className='buttonx' type='button' onClick={handlePrevStep}>Back</button>
                            <button className='buttonx' type='submit'>Send</button>
                        </>
                    )}
                </form>
            </div>
        </section>
    );
}

export default Form;
