import React from 'react';
import { useNavigate } from 'react-router-dom';
import {FaCheck} from 'react-icons/fa'


const Success = () => {

    const navigate = useNavigate();

    const clickFunction = () => {
        navigate("/")
    }

    return (
        <section className="Success" id="Success">
            <div className="SuccessContainer">
<FaCheck style={{ color: "orangered", fontSize: "38px" }}  />
                <h1>Thankyou for filling the information!.</h1>
                <p> We will get back to you with a price quotation shortly</p>
                
                <button className="buttonx5" onClick={clickFunction}>Go Back</button>

            </div>


        </section>
    )
}

export default Success