import React from "react";
import { useEffect, useState } from "react";
import { GiCrossedAxes, GiHamburgerMenu, GiSmartphone } from 'react-icons/gi';
import { FaPhoneAlt } from 'react-icons/fa';
import logo from "../assets/img/logous.png";
import "react-icons/gi";
import { NavLink, useLocation } from 'react-router-dom';

export const NavBar = () => {

  const [toggleMenu, setToggleMenu] = React.useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    // Update the active link when the location changes
    setActiveLink(location.pathname);
  }, [location]);


  return (
    <div className="Navbar">
      <div className="NavbarContainer">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="tasveer" /></a>
        </div>
        <div className='links'>
      <NavLink to="/" className={activeLink === '/' ? 'active-link' : ''}>Home</NavLink>
      <a href="/#services" className={activeLink === '#services' ? 'active-link' : ''}>Services</a>
      <a href="/#fleet" className={activeLink === '#fleet' ? 'active-link' : ''}>Fleet</a>
      <a href="/#reviews" className={activeLink === '#reviews' ? 'active-link' : ''}>Reviews</a>
      <a href="/#faqs" className={activeLink === '#reviews' ? 'active-link' : ''}>FAQs</a>
      
      
</div>
<div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="black"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay slide-left">
            <GiCrossedAxes
            color="goldenrod"
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <NavLink to="/" onClick={() => setToggleMenu(false)}>
                  Home
                </NavLink>
              </li>
              <li>
          
              <a href="#fleet" 
              onClick={() => setToggleMenu(false)} className={activeLink === '#fleet' ? 'active-link' : ''}>Fleet</a>
      
              </li>

              <li>
              <a href="#services" 
              onClick={() => setToggleMenu(false)} className={activeLink === '#fleet' ? 'active-link' : ''}>Services</a>
      
              </li>

              <li>
              <a href="#reviews" 
              onClick={() => setToggleMenu(false)} className={activeLink === '#fleet' ? 'active-link' : ''}>Reviews</a>
      
              </li>

              <li>
              <a href="#faqs" 
              onClick={() => setToggleMenu(false)} className={activeLink === '#fleet' ? 'active-link' : ''}>FAQs</a>
      
              </li>

              
            </ul>
          </div>
        )}
      </div>

      

        

        
            <button className="button">
              <div> <FaPhoneAlt style={{ marginRight: "5px" }} size={14} /><a style={{ textDecoration: "none",color:"white" }} href="tel:14752634964" >(475) 263-4964</a></div>
            </button>

    

    
      </div>
    </div>
  );
};
