import React from "react";
import { FaCheck, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import pics from "../assets/img/limo1.jpg";

export const Services = () => {
  return (
    <section className="Services">
      

      <div className="Moving">
        <h1 style={{fontSize:"34px"}} >Mission Statement</h1>
        <p>

        <FaQuoteLeft fontSize="2em" /> <br/> Our dedication extends to constant innovation, 
        integrating state-of-the-art technology for seamless experiences while prioritizing sustainability.
         Beyond meeting standards, our rigorous safety protocols ensure peace of mind. Our commitment to service 
         excellence tailors every journey, creating personalized, unforgettable experiences. Fostering enduring
          relationships, both locally and globally, remains central to our ethos. With a focus on reliability
         and a personal touch, we aim to redefine travel, making each journey more than just transportation.</p>
        
        
      </div>

      <div className="ServicesPics">
        <img src={pics} alt="tasveer" width={1200} />
      </div>
    </section>
  );
};
