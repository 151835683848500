import React, { useState } from "react";
import "./faqs.css"; // Be sure to create the corresponding CSS file
import { FaQuora } from "react-icons/fa";

const Plan = () => {
    const faqs = [
        {
            category: "General Information",
            items: [
                {
                    question: "What types of services does North Star Limo offer?",
                    answer: "North Star Limo offers a wide range of services, including airport transfers, corporate transportation, special event services (weddings, proms, parties), city tours, and hourly charters.",
                },
                {
                    question: "How can I book a limo with North Star Limo?",
                    answer: "You can book a limo by visiting our website, calling our customer service number, or using our mobile app.",
                },
                {
                    question: "What areas do you serve?",
                    answer: "We primarily serve New York City and surrounding areas, including Manhattan, Brooklyn, Queens, the Bronx, and Staten Island. We also offer transportation to and from major airports like JFK, LaGuardia, and Newark.",
                },
            ],
        },
        {
            category: "Booking and Payments",
            items: [
                {
                    question: "How far in advance should I book my limo?",
                    answer: "We recommend booking your limo at least 24 hours in advance to ensure availability. For special events, it's best to book as early as possible.",
                },
                {
                    question: "What payment methods do you accept?",
                    answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover. We also accept payments via PayPal and bank transfers.",
                },
                {
                    question: "Is a deposit required when booking?",
                    answer: "Yes, a deposit is required to confirm your reservation. The deposit amount will be specified during the booking process.",
                },
                {
                    question: "Can I cancel my reservation?",
                    answer: "Yes, you can cancel your reservation. Please refer to our cancellation policy on our website for specific details and any applicable fees.",
                },
            ],
        },
        {
            category: "Services and Vehicles",
            items: [
                {
                    question: "What types of vehicles are available in your fleet?",
                    answer: "Our fleet includes luxury sedans, SUVs, stretch limousines, party buses, and executive vans. All vehicles are equipped with modern amenities for your comfort and convenience.",
                },
                {
                    question: "Are your vehicles insured and licensed?",
                    answer: "Yes, all our vehicles are fully licensed and insured in accordance with local and state regulations.",
                },
                {
                    question: "Do your chauffeurs undergo background checks and training?",
                    answer: "Absolutely. All our chauffeurs undergo thorough vetting, comprehensive background checks, and extensive training to ensure your safety and comfort.",
                },
                {
                    question: "Can I request a specific vehicle or chauffeur?",
                    answer: "While we cannot guarantee specific vehicle or chauffeur requests, we will do our best to accommodate your preferences.",
                },
            ],
        },
        {
            category: "Special Requests and Customization",
            items: [
                {
                    question: "Can you accommodate special requests for events like weddings or proms?",
                    answer: "Yes, we specialize in providing customized services for special events. Please contact us with your specific requirements, and we'll create a tailored package for you.",
                },
                {
                    question: "Do you offer child seats?",
                    answer: "Yes, we provide child seats upon request. Please specify the type and number of child seats needed when making your reservation.",
                },
                {
                    question: "Can you arrange for decorations or refreshments in the limo?",
                    answer: "Yes, we can arrange for decorations and refreshments upon request. Please let us know your requirements in advance so we can make the necessary arrangements.",
                },
            ],
        },
        {
            category: "Pricing and Discounts",
            items: [
                {
                    question: "How are your rates determined?",
                    answer: "Our rates are based on the type of vehicle, duration of service, and distance traveled. For a detailed quote, please use our online booking tool or contact our customer service team.",
                },
                {
                    question: "Do you offer any discounts or promotions?",
                    answer: "Yes, we offer various discounts and promotions throughout the year. Please check our website or sign up for our newsletter to stay informed about our latest offers.",
                },
                {
                    question: "Are there any additional charges I should be aware of?",
                    answer: "Additional charges may include tolls, parking fees, and gratuity. All applicable fees will be detailed in your booking confirmation.",
                },
            ],
        },
        {
            category: "Safety and Cleanliness",
            items: [
                {
                    question: "What measures do you take to ensure the cleanliness and safety of your vehicles?",
                    answer: "We adhere to strict cleanliness protocols, including thorough cleaning and sanitization of vehicles before and after each ride. Our chauffeurs follow all recommended safety guidelines to ensure your well-being.",
                },
                {
                    question: "How do you handle lost items?",
                    answer: "If you believe you have left an item in one of our vehicles, please contact our customer service team as soon as possible. We will do our best to locate and return your item.",
                },
            ],
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section className="faq-container" id="faqs">
            <p style={{ color: "orange", fontWeight: "bold" }}>Your FAQs</p>
            <h1>Frequently Asked Questions</h1>
            <p>In Search of Answers? Look No Further – Our FAQ section is here
                to address your concerns and guide you through the car shipping process</p>
            <br />

            {faqs.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                    <h2>{category.category}</h2>
                    <ul className="faq-list">
                        {category.items.map((faq, index) => (
                            <li key={index} className="faq-item">
                                <button
                                    className="faq-question"
                                    onClick={() => handleAccordionClick(categoryIndex * 1000 + index)} // unique key for each FAQ item
                                >
                                    <FaQuora color="orange" size={20} /> {faq.question}
                                </button>
                                {activeIndex === categoryIndex * 1000 + index && (
                                    <div className="faq-answer">{faq.answer}</div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </section>
    );
};

export default Plan;
