import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";


export const Strip = () => {

  

  return (

    <div className="Strip">
      
      <div className="tag">
        <h2 style={{ color: "black" }} >Dial: <a style={{ textDecoration: "none", color: "black" }} href="tel:14752634964" >(475) 263-4964</a></h2></div>


      <div className="tag">
        <h2 style={{ color: "black" }} >Email: <a style={{ textDecoration: "none", color: "black" }} href="mailto:contact@mynorthstarlimo.com ">contact@mynorthstarlimo.com
        </a></h2></div>

    </div>

  );
};
