import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home";

import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { Strip } from "../components/Strip";

import { Plan } from "../components/Plan";
import Success from "../pages/page";


const Navigation = () => {

    return (
        <Router>
            <Strip />
            <NavBar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/success' element={<Success />} />

            </Routes>

            <Footer />
        </Router>
    )
}

export default Navigation;