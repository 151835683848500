import React from "react";
import { FaBoxOpen, FaCarAlt, FaCarSide, FaLock, FaMobile, FaMotorcycle, FaTools } from "react-icons/fa";
import pic1 from "../assets/img/airport.jpg";
import pic2 from "../assets/img/smileman.jpg";
import pic3 from "../assets/img/oldman.jpg";
import pic4 from "../assets/img/prom.jpg";
import pic5 from "../assets/img/nightout.jpg";
import pic6 from "../assets/img/tour.jpg";
import pic7 from "../assets/img/wedding.jpg";
import { IoMdBoat } from "react-icons/io";


export const Banner = () => {
   return (
      <section className="Banner" id="services">
         <div className="BannerContainer">
            <h1 style={{ fontSize: "40px",color:"orangered" }} >Our Services</h1>
            <p>North Star Limo offers an exclusive array of premier transportation services<br />
                tailored to elevate every journey. From luxurious executive travel that exudes
                 sophistication to seamless airport transfers ensuring<br /> a stress-free start or
                  end to your travels, our services cater to diverse needs with unparalleled finesse.<br /> We specialize
                in providing bespoke event transportation, ensuring seamless arrivals<br /> and departures for any special occasion.
            </p>
            <br /><br />

            <div className="PlansContainer">

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic1} />
                  </div>
                  <h2>AIRPORT SERVICES</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic7} /></div>
                  <h2>WEDDING SERVICES</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic5} /> </div>
                  <h2>NIGHT-OUT SERVICES</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic3} />  </div>
                  <h2>CORPORATE TRANSPORTATION</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic2} /> </div>
                  <h2>PRIVATE TRANSPORTATION</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic4} /> </div>
                  <h2>PROM LIMOS</h2>
               </div>

               <div className="PlansItems">
                  <div className="icon">
                     <img src={pic6} /></div>
                  <h2>EXCURSIONS & TOURS</h2>
               </div>

            </div>

         </div>


      </section>
   );
};
